<template>
  <v-dialog
    v-model="show"
    persistent
    :max-width="800">
   <v-card
    class="d-flex flex-column align-center pa-3">
    <v-card-title
      class="primary--text d-flex flex-column align-center justify-center">
      <v-icon
        color="success"
        size="30">
        fas fa-check
      </v-icon>
      <h3>
        Movimentação criada com sucesso!
      </h3>
    </v-card-title>

    <v-card-subtitle class="pt-2 primary--text">
      <label class="text-subtitle-1">
        <strong>{{ movement.movementType }}: </strong>
        <span>{{ movement.beneficiaryName }}</span>
      </label>
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col
          class="d-flex justify-center"
          :order="orderMakeNewMovement">
          <v-btn large outlined color="primary" @click="makeNewMovement">
            <v-icon left>mdi mdi-file-plus</v-icon>
            <span>REALIZAR NOVA MOVIMENTAÇÃO</span>
          </v-btn>
        </v-col>
        <v-col
          class="d-flex justify-center"
          :order="orderGoToMovementList">
          <v-btn large color="primary" @click="goToMovementList">
            <v-icon left>fa-thin fa-clipboard-check</v-icon>
            <span>IR PARA A LISTA DE MOVIMENTAÇÕES</span>
          </v-btn>
        </v-col>
        <slot name="buttons"></slot>
      </v-row>
    </v-card-text>
   </v-card>
  </v-dialog>
  </template>

<script>
import Formatters from '@/shared/formatters/formatters';

export default ({
  name: 'ReleaseMovementModal',

  data: () => ({
    show: false,
    movement: {
      beneficiaryName: '-',
      movementType: 'Tipo da movimentação',
    },
  }),

  props: {
    orderMakeNewMovement: {
      type: Number,
      default: 1,
    },
    orderGoToMovementList: {
      type: Number,
      default: 2,
    },
  },

  created() {
    this.formatter = new Formatters();
  },

  methods: {
    makeNewMovement() {
      this.$emit('makeNewMovement');
    },

    goToMovementList() {
      this.$emit('goToMovementList');
    },

    open(data) {
      this.movement.beneficiaryName = data.beneficiaryName ? this.formatter.formatToTitleCase(data.beneficiaryName) : '-';
      this.movement.movementType = data.movementType;

      this.show = true;
    },

    close() {
      this.show = false;
    },
  },
});
</script>
