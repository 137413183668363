/* eslint-disable */
import HttpService from '../HttpService'

export default class MovementFileService {
  constructor() {
    this._httpService = new HttpService('/sdi/movement-file');
    this._httpLayoutFileService = new HttpService('/sdi/layout-file');
  }

  async Upload(data) {
    return await this._httpService.post('/v2', data);
  }

  async DownloadFile(financialGroupId){
    return await this._httpLayoutFileService.get(`?financialGroupId=${financialGroupId}`);
  }

  async DownloadFile(financialGroupId){
    return await this._httpLayoutFileService.get(`?financialGroupId=${financialGroupId}`);
  }
}
