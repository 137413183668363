/* eslint-disable */
import HttpService from '../HttpService'

export default class FieldTypeService {
  constructor() {
    this._httpService = new HttpService('/sdi/datasource');
    this._httpServiceColumn = new HttpService('/sdi/datasource-column');
  }

  async FindAll() {
    return await this._httpService.get('');
  }

  async FindAllColumn() {
    return await this._httpServiceColumn.get('');
  }
}
