import HttpService from '../HttpService';

export default class ContractContributoryService {
  
  constructor() {
    this._httpService = new HttpService('/contract/contract-contributory');
  }

  async CountByContractIdAndPlanIdAndBeneficiaryType(params) {
    return await this._httpService.get('/countByContractIdAndPlanIdAndBeneficiaryType', params);
  }

};